import React, { useState, useEffect, useRef /* , useEffect, useRef, useCallback*/ } from 'react'
// import shuffle from 'lodash/shuffle'
import Link from 'gatsby-link'
import * as styles from './interview.module.scss'
import InterviewReferencePlayer from '../components/interviewReferencePlayer'
import { getScrollY } from '../helpers'
import InterviewMessages from '../components/interviewMessages'

const references = [
  {
    slug: 'rick_rubin',
    images: [
      '/interview-images/rickrubin/2013RickRubinPA-4359971060813-2.jpg',
      '/interview-images/rickrubin/2013RickRubinPA-4373578060813-1.jpg',
      '/interview-images/rickrubin/b4208e6aae1c3828240a1afcb5687c2294-rick-rubin-00s.rsquare.w700.webp',
      '/interview-images/rickrubin/ca-times.brightspotcdn.jpg',
      '/interview-images/rickrubin/rick_rubin.jpg',
      '/interview-images/rickrubin/RickRubinSept09.jpg',
      '/interview-images/rickrubin/static1.squarespace.jpg',
    ]
  },
  {
    slug: 'hiphoplabel',
    images: [
      '/interview-images/hiphoplabel/4ea5b2fb69bedd416a000016.webp',
      '/interview-images/hiphoplabel/1986_1104_Rubin_19-scaled-1.jpeg',
      '/interview-images/hiphoplabel/61655923f0bf9b0e7b7f6aa54b16f18f34a21db8-1543x1030.jpg',
      '/interview-images/hiphoplabel/defjam_p002-003_wide-fd2a21ed437503fbc12cf2e8f6ead7a150ac04e5-s1100-c50.jpg',
      '/interview-images/hiphoplabel/defjam_p019.jpg.webp',
      '/interview-images/hiphoplabel/Unknown.jpeg'
    ]
  },
  {
    slug: 'rhcp',
    images: [
      '/interview-images/rhcp/pW7MoMV8Lv8yOsa1H7Rlx8v0oMU.jpg',
      '/interview-images/rhcp/rhcprubin.jpg',
      '/interview-images/rhcp/sddefault.jpg'
    ]
  },
  {
    slug: 'johnny_cash',
    images: [
      '/interview-images/JohnnvCash/Eh5YSsqXYAMgkKo.jpg',
      '/interview-images/JohnnvCash/gettyimages-1275625978-612x612.jpg',
      '/interview-images/JohnnvCash/image.webp',
      '/interview-images/JohnnvCash/los-angeles-ca-johnny-cash-tom-petty-mike-campbell-marty-stuart-benmont-GF5DKG.jpg',
      '/interview-images/JohnnvCash/maxresdefault.jpg',
      '/interview-images/JohnnvCash/rick-rubin-johnny-cash@2000x1500-1200x900.jpg',
      '/interview-images/JohnnvCash/TXUoQ8N4TyMT0mUpNiHKroIbsggqKcxTo7NRELquBhk.webp',
      '/interview-images/JohnnvCash/Unknown.jpeg'
    ]
  },
  {
    slug: 'qt',
    images: [
      '/interview-images/QT/29920305_2015-10-01_Quentin-_mark_Tarantino__mark_-a.jpg',
      '/interview-images/QT/83579900ea152d290caccba0c0d12eca.jpg',
      '/interview-images/QT/9780859652704-us.jpg',
      '/interview-images/QT/pulp_quentin_704.jpg',
      '/interview-images/QT/tarantino.jpg'
    ]
  },
  {
    slug: 'pam_grier',
    images: [
      '/interview-images/PAMGRIER/il_1080xN.1743351864_8luv.jpg',
      '/interview-images/PAMGRIER/maxresdefault.jpg',
      '/interview-images/PAMGRIER/MV5BMjIzMDc3ODEwOF5BMl5BanBnXkFtZTcwMjQ0OTAyNg@@._V1_.jpg',
      '/interview-images/PAMGRIER/s-l1600.jpg',
      '/interview-images/PAMGRIER/s-l1600.png'      
    ]
  },
  {
    slug: 'curiosity',
    images: [
      '/interview-images/curiosity/logo2-10 copy.png',
    ]
  },
  {
    slug: 'virgil',
    images: [
      '/interview-images/virgil/09-Ph-Valerie-Macon-AFP-Getty-Images-en.jpg',
      '/interview-images/virgil/EDITION_5_FINAL-small-67.jpg',
      '/interview-images/virgil/images.jpeg',
      '/interview-images/virgil/maxresdefault.jpg',
      '/interview-images/virgil/Screen-Shot-2023-05-08-at-6.47.10-PM.jpg',
      '/interview-images/virgil/Screen-Shot-2023-05-08-at-6.48.06-PM.jpg',
      '/interview-images/virgil/tumblr_78b88e2ac8d7096486abdc0f2c547c15_034bcad4_1280.jpg',
      '/interview-images/virgil/virgil-abloh-deck-360x467.jpg',
      '/interview-images/virgil/WAG1MAG-VIRGIL-ABLOH-PIONEER-2-1024x464.jpg'
    ]
  },
  {
    slug: 'richard_prince',
    images: [
      '/interview-images/RichardPrince/BN-FQ796_mag121_FR_20141120133440.jpg',
      '/interview-images/RichardPrince/HIPPIE_DRAWINGS_1.webp',
      '/interview-images/RichardPrince/image-asset.jpg',
      '/interview-images/RichardPrince/images.jpeg',
      '/interview-images/RichardPrince/IMG_6909.jpeg',
      '/interview-images/RichardPrince/nv4Rm7R4KU_A_1140x1140.jpg',
      '/interview-images/RichardPrince/old richard prince pics.jpg',
      '/interview-images/RichardPrince/prince01_body.jpg',
      '/interview-images/RichardPrince/qaprince1115-1-700.webp',
      '/interview-images/RichardPrince/Richard-Prince-CEPA-photo-booth-1979-1980-exhibition-BIG-min.gif',
      '/interview-images/RichardPrince/Richard-Prince-via-vulture-com.jpg',
      '/interview-images/RichardPrince/screen-shot-2018-11-06-at-10-26-51-am-1541518066.png',
      '/interview-images/RichardPrince/TR500-2000-498.jpeg',
      
      '/interview-images/RichardPrince/X7XN8Foo-WxT_585x329.jpg'
    ]
  },
  {
    slug: 'car_hoods',
    images: [
      '/interview-images/carhoods/2005.59_ph_web-1.jpg',
      '/interview-images/carhoods/3834d42cfc945c6457b20fbc09e1cb15.jpg',
      '/interview-images/carhoods/3890.jpg',
      '/interview-images/carhoods/installation-srgm-richard-prince-spiritual-america-ph_016.jpg',
      '/interview-images/carhoods/Mi6mbAJZP_ch5L1A.jpeg',
      '/interview-images/carhoods/PEIPFAyXa8JF_585x329.jpg',
      '/interview-images/carhoods/richard_prince_hoods_poster_1200x1200.jpg.webp',
      '/interview-images/carhoods/thumb_8664_1120_0_0_0_auto.jpg'
    ]
  },
  {
    slug: 'instagram_shit',
    images: [
      '/interview-images/rpinsta/5-richard-prince-exterior-11-lance-gerber.jpg',
      '/interview-images/rpinsta/10-richard-prince-interior-1-lance-gerber.jpg',
      '/interview-images/rpinsta/900.jpg.webp',
      '/interview-images/rpinsta/150527073820-richard-prince-frieze.jpg',
      '/interview-images/rpinsta/d7hftxdivxxvm.cloudfront.net.webp',
      '/interview-images/rpinsta/emrata_2_2.jpg',
      '/interview-images/rpinsta/Gagosian-Beverly-Hills-Richard-Prince.jpg',
      '/interview-images/rpinsta/IMG_3765copie.jpg',
      '/interview-images/rpinsta/ivanka-trump-richard-prince-lede-720x461.webp',
      '/interview-images/rpinsta/Prince2.jpg'
    ]
  },
  {
    slug: 'henry_taylor',
    images: [
      '/interview-images/Henrytaylor/HT261_thumbnail-700-xxx_q85.jpg',
      '/interview-images/Henrytaylor/image_processing20210114-4-dy0onc.jpg',
      '/interview-images/Henrytaylor/images.jpeg',
      '/interview-images/Henrytaylor/img_5488.jpg.webp'
    ]
  },
  {
    slug: 'kehin',
    images: [
      '/interview-images/kehindewiley/2012_6.jpg',
      '/interview-images/kehindewiley/Kehinde-Wiley-Officer-of-the-Hussars-2007.jpg',
      '/interview-images/kehindewiley/kehinde-wiley.jpeg'
    ]
  },
  {
    slug: 'kerry',
    images: [
      '/interview-images/kjm/28_001.jpg',
      '/interview-images/kjm/765n10429-bnnlv-rs.jpg',
      '/interview-images/kjm/Could-This-Be-Love-final.jpg',
      '/interview-images/kjm/Kerry-James-Marshall_FlashArt2.jpg'
    ]
  },
  {
    slug: 'spike_lee',
    images: [
      '/interview-images/spikelee/3f9d12e6adcf751e8b6a9c7c421fbb74--eddie-murphy-spike-lee.jpg',
      '/interview-images/spikelee/2015111300002808.jpg',
      '/interview-images/spikelee/blog_spikelee.jpg',
      '/interview-images/spikelee/f9ddb0700e525a00c86809a034c1887b--spike-lee-joint.jpg',
      '/interview-images/spikelee/RSB04627_0e9028c8-3654-47d9-b19a-a89be527be2d_1200x.jpg.webp',
      '/interview-images/spikelee/sub-buzz-3487-1643733340-34.jpg',
      '/interview-images/spikelee/sub-buzz-4163-1643738603-19.jpg',
    ]
  },
  {
    slug: 'do_the_right',
    images: [
      '/interview-images/dothrightthing/5c7b9b1baa4672584033d3707560f506.jpg',
      '/interview-images/dothrightthing/268w3itgxhq71.jpg',
      '/interview-images/dothrightthing/2015111300002808.jpg',
      '/interview-images/dothrightthing/2015111300002809.jpg',
      '/interview-images/dothrightthing/ada98c53e78df9aa1611d86d53dbd811.jpg',
      '/interview-images/dothrightthing/ca-times.brightspotcdn.jpg',
      '/interview-images/dothrightthing/cinematographers-turned-directors-ernest-dickerson.jpg',
      '/interview-images/dothrightthing/do-the-right-thing-md-web.jpg',
      '/interview-images/dothrightthing/image-w1280.jpg',
      '/interview-images/dothrightthing/MV5BMTE0MjAyNzM5ODJeQTJeQWpwZ15BbWU4MDM4MzM3MDMy._V1_.jpg',
      '/interview-images/dothrightthing/sub-buzz-3487-1643733340-34.jpg',
      '/interview-images/dothrightthing/tumblr_m6be8ndZi11rujq2eo1_1280.jpg'
    ]
  },
  {
    slug: 'hour_25',
    images: [
      '/interview-images/25thhour/25thhour_2002_original_film_art_1200x.jpg.webp',
      '/interview-images/25thhour/MCDTWFI_EC006-copy.jpg.webp',
      '/interview-images/25thhour/MV5BNWY4Njc2NGMtOGM3Zi00ZmEyLWE5MWMtOGQwNDlmNDNlYmM1XkEyXkFqcGdeQXVyOTc5MDI5NjE@._V1_.jpg',
      '/interview-images/25thhour/MV5BODYyODYwNTYyOF5BMl5BanBnXkFtZTYwODQyNjU3._V1_.jpg',
      '/interview-images/25thhour/rosario-dawson-25th-hour-2002-2JHDFK6.jpg'
    ]
  },
  {
    slug: 'girl_6',
    images: [
      '/interview-images/girl6/51ABMN3XVGL._AC_UF894,1000_QL80_.jpg',
      '/interview-images/girl6/Girl_6-442283049-large.jpg',
      '/interview-images/girl6/girl-6-girl-6-usa-1996-regie-spike-lee-theresa-randle-isaiah-washington-H89JAF.jpg',
      '/interview-images/girl6/girl6_002.jpg',
      '/interview-images/girl6/girl6.jpg',
      '/interview-images/girl6/Screen-Shot-2023-05-29-at-2.06.44-PM.jpg',
      '/interview-images/girl6/Screen-Shot-2023-05-29-at-2.11.25-PM.jpg',
      '/interview-images/girl6/vlcsnap-0691.png'
    ]
  },
  {
    slug: 'philip_seymour',
    images: [
      '/interview-images/PhilipSeymour/article-2551146-1AEA30BD00000578-941_306x423.jpg',
      '/interview-images/PhilipSeymour/FYVhFDQUIAELltd.jpg-large.jpeg',
      '/interview-images/PhilipSeymour/images.jpeg',
      '/interview-images/PhilipSeymour/img-philip-seymour-hoffman_113603635489.jpg'
    ]
  },
  {
    slug: 'kanye',
    images: [
      '/interview-images/kanyewest/a-head-to-toe-silver-kanye-west-gave-us-some-golden-memes800-1576063598.jpg',
      '/interview-images/kanyewest/kanye-west-and-drake-kissing-v0-j1wkpvv7di0a1.jpg.webp',
      '/interview-images/kanyewest/self.png',
      '/interview-images/kanyewest/Screen-Shot-2023-05-08-at-6.45.45-PM.jpg',
    ]
  },
  {
    slug: 'pink_polo',
    images: [
      '/interview-images/pinkpolo/images.jpeg'
    ]
  },
  {
    slug: 'eight_oh_eight',
    images: [
      '/interview-images/808/970-9705899_kanye-west-808-heartbreak-download-kanye-west-808s.png.jpeg',
      '/interview-images/808/ddhwdvk9pmo81.jpg.webp',
      '/interview-images/808/kanye_2_pag7cz.webp',
      '/interview-images/808/kanye_west__808s_and_heartbrea_1628823176_994b8d2b_progressive.jpg',
      '/interview-images/808/tumblr_n9weskzj2z1rnfe38o1_1280-e1541698905949.jpg'
    ]
  },
  {
    slug: 'mbatf',
    images: [
      '/interview-images/mdbtf/0x0.jpg',
      '/interview-images/mdbtf/440px-Control_room_with_SSL_9000J_&_custom_3-way_main_monitors,_Avex_Honolulu_Studios.jpg',
      '/interview-images/mdbtf/jmp5wKnfCQApGaeVI3NLGwFo9UAiOvrJgCgnCDYJIsA.jpg.webp',
      '/interview-images/mdbtf/mom84nyebzz41.jpg',
      '/interview-images/mdbtf/Unknown.jpeg'
    ]
  },
  {
    slug: 'travis_scott',
    images: [
      '/interview-images/travisscott/glo.jpeg',
      '/interview-images/travisscott/aae11dfb-bcfb-4fb3-8d02-61b05d135ba7-1659159269918.jpeg',
      '/interview-images/travisscott/DOy1DwCWsAEUv_c.jpg'
    ]
  },
  {
    slug: 'bamboozled',
    images: [
      '/interview-images/bamboozled/81AtZUPH7oL._RI_.jpg',
      '/interview-images/bamboozled/1019_image_10.jpg',
      '/interview-images/bamboozled/original-film-title-bamboozled-english-title-bamboozled-film-director-spike-lee-year-2000-stars-jada-pinkett-smith-damon-wayans-credit-new-line-cinema-lee-david-album-P0P05J.jpg',
      '/interview-images/bamboozled/original-film-title-bamboozled-english-title-bamboozled-film-director-spike-lee-year-2000-stars-jada-pinkett-smith-savion-glover-credit-new-line-cinema-lee-david-album-P0P05R.jpg',
      '/interview-images/bamboozled/spike-lee-dir-os-bamboozled-2000-with-damon-wayans-skle-066-BKJNC0.jpg'
    ]
  },
  {
    slug: 'acrylic_nails',
    images: [
      '/interview-images/acrylicnails/IMG_5172.jpeg',
      '/interview-images/acrylicnails/IMG_5173.jpeg',
      '/interview-images/acrylicnails/IMG_5174.jpeg'
    ]
  },
  {
    slug: 'clockers',
    images: [
      '/interview-images/clockers/Clockers_28Disc.JPG.webp',
      '/interview-images/clockers/clockers-1995-001-mekhi-phifer-00m-fxy-e1589461980782.jpg',
      '/interview-images/clockers/clockers-DXJ8YJ.jpg',
      '/interview-images/clockers/Clockers-Surrealistic-Flashback-2.webp',
      '/interview-images/clockers/clockers.jpg',
      '/interview-images/clockers/e73a54c58a82276f61d6504840c6d66c.jpg',
      '/interview-images/clockers/EwjCMaSVkAEZ1p0.jpg',
      '/interview-images/clockers/f401cd2f3fa916299c7c9c7114f7987a--mekhi-phifer-pimp.jpg',
      '/interview-images/clockers/H040c5ace28044c32bb3cae28df22dc20h.jpg_640x640Q90.jpg_.webp',
      '/interview-images/clockers/i-watched-clockers-for-the-first-time-in-decades-whenever-i-v0-tmqfbmclost81.webp',
      '/interview-images/clockers/imago0094780543w.jpg',
      '/interview-images/clockers/martin-scorsese-and-spike-lee-clockers-1995-2B6M51R.jpg',
      '/interview-images/clockers/MV5BY2I0Mzg5OWEtYjE1Ny00MjBjLTkwMjEtMjkxNTVlY2Q0OTk1XkEyXkFqcGdeQXVyNjcwODc2NDY@._V1_.jpg',
      '/interview-images/clockers/s-l1600.jpg',
      '/interview-images/clockers/spike-lee-dir-os-clockers-1995-skle-040-BKJNBD.jpg'
    ]
  },
  {
    slug: 'yeezus',
    images: [
      '/interview-images/yeezus/download.jpg',
      '/interview-images/yeezus/kanye-sept-15-2014.jpg',
      '/interview-images/yeezus/kanye-west-yeezus-5-years-later-01.webp',
      '/interview-images/yeezus/rs-13565-kanye-1800-1385144890.webp',
      '/interview-images/yeezus/tumblr_mor2ykN1nz1qft2qbo1_640.jpg',
      '/interview-images/yeezus/yeezus_album_kanye_west_1629480346_e747f45e.jpg',
    ]
  }  
]

const ConversationPage = () => {
  const lastReferenceActivationScrollPosition = useRef(0)
  const headerImageRef = useRef()
  const [activeReferenceSlug, setActiveReferenceSlug] = useState(null)
  const [backgroundVisible, setBackgroundVisible] = useState(false)
  const [titleHidden, setTitleHidden] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = getScrollY()

      const diff = Math.abs(scrollY - lastReferenceActivationScrollPosition.current)

      if (diff > 10) {
        setBackgroundVisible(false)
      }

      if (scrollY > (headerImageRef.current.offsetTop + headerImageRef.current.offsetHeight - 250)) {
        setTitleHidden(true)
      }
      else {
        setTitleHidden(false)
      }
    };
    
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (activeReferenceSlug) {
      lastReferenceActivationScrollPosition.current = getScrollY()
    }
  }, [activeReferenceSlug])

  const handleReferenceClick = (e, referenceSlug) => {
    e.preventDefault()

    // if (referenceSlug === activeReferenceSlug) {
    //   setBackgroundVisible(false)
    // }
    // else {
      setActiveReferenceSlug(referenceSlug)
      setBackgroundVisible(true)
    // }
  }

  const backgroundClasses = [
    styles.background
  ]

  const titleClasses = [
    styles.title
  ]

  if (backgroundVisible) {
    backgroundClasses.push(styles.backgroundVisible)
  }

  if (titleHidden) {
    titleClasses.push(styles.titleHidden)
  }

  return (
    <React.Fragment>     
      <div>
        <div className={styles.wrapper}>
          <div className={styles.column}>
            <div className={titleClasses.join(' ')}>
              A conversation with SAJI
            </div>

            <div
              ref={headerImageRef}
              style={{ padding: '0 30px', marginBottom: 150 }}
            >
              <img src="/interview-images/saji.jpg" height="1010" width="1000" className={styles.headerImage} />
            </div>

            <div style={{ textAlign: 'center', marginBottom: 50 }}>
              <strong>Wed, Nov 16 at</strong> 7:20 PM
            </div>

            <InterviewMessages
              handleReferenceClick={handleReferenceClick}
            />

            <div style={{ textAlign: 'center', marginTop: '30vh' }}>
              <Link to={'/about'}>About</Link>
              {/* <a href="mailto:steventraylor96@gmail.com" target="_blank">Contact</a>   */}
            </div>      
          </div>
        </div>
      </div>


      <div 
        className={backgroundClasses.join(' ')}
        onClick={() => setBackgroundVisible(false)}
      >
        {references.map(r => {
          return (
            <InterviewReferencePlayer
              key={r.slug}
              reference={r}
              active={activeReferenceSlug === r.slug}
            />
          )
        })}
      </div>
    </React.Fragment>
  )
}

export default ConversationPage