import React from 'react'
import * as styles from '../pages/interview.module.scss'

const InterviewMessages = ({ handleReferenceClick }) => {
  return (
    <div className={styles.messages}>
      <div className={styles.messagesBlock}>
        <div className={styles.dialog}>
          <a href="https://www.instagram.com/tv/ClCygDdOwX5" target="_blank">
            <img src="/interview-images/DIALOG/snoop.jpg" />
            <div className={styles.dialogCaption}>
              @theartofdialogue on Instagram: "#SnoopDogg Says He Didn't Become A Star Until He Got Next To #2Pac...
            </div>
          </a>
        </div>        
        <p>Steven: Kinda what we was just talking about earlier today</p>
        <p>The algorithm bustin tonight <img src="/interview-images/emoji-tears-of-joy.png" className={styles.emoji}/></p>
      </div>

      <div className={styles.messagesBlock}>
        <p>Saji: Fr</p>
        <p>Looking at situations and people for what it is, not what people project on to it</p>
        <p>Putting ego aside for the work/best result</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>Exactly</p>
      </div>
        
      <div className={styles.messagesBlock}>
        <p>
          I feel like thats the key to
          <a href="#" style={{ color: '#e52960' }} onClick={(e) => handleReferenceClick(e, 'rick_rubin')}> Rick Rubens </a>
          success
        </p>
        <p>And why he could work w so many genres</p>
        <div className={styles.dialog}>
          <a href="https://www.youtube.com/watch?v=PnahkJevp64" target="_blank">
            <img src="/interview-images/DIALOG/PnahkJevp64-HD.jpg" />
            <div className={styles.dialogCaption}>
              Pharrell and Rick Rubin Have an Epic Conversation | GQ
            </div>
          </a>
        </div>
      </div>

      <div className={styles.messagesBlock}>
        <p>Yeah</p>
        <p>Like it seems like he was never a person to not fuc wit someone because of what they're known for</p>
        <p>Like just because he's known for starting a <a href="#" style={{ color: '#30b44a' }} onClick={(e) => handleReferenceClick(e, 'hiphoplabel')}>hip-hop label</a>.  Doesn't mean he can't fuc with the <a href="#" style={{ color: '#ce2454' }} onClick={(e) => handleReferenceClick(e, 'rhcp')}>Red Hot Chili Peppers</a></p>
      </div>

      <div className={styles.messagesBlock}>
        <p>Exactly</p>
        <p>He talks about that on his podcast</p>
        <p>In a specific instance working w <a href="#" style={{ color: '#126c94' }} onClick={(e) => handleReferenceClick(e, 'johnny_cash')}>Johnny Cash</a> when no one was fucking w him anymore, just to see if he could basically</p>
      </div>
      
      <div className={styles.messagesBlock}>
        <p>Yup!</p>
        <p>The best do that</p>
        <p>Taking on a challenge</p>
        <p>Stepping outta ur comfort zone</p>
        <p>That's why I fuc with <a href="#" style={{ color: 'rgb(200 20 146)' }} onClick={(e) => handleReferenceClick(e, 'qt')}>QT</a> so much</p>
        <p>To even think about bringing back <a href="#" style={{ color: 'rgb(197 59 22)' }} onClick={(e) => handleReferenceClick(e, 'pam_grier')}>Pam Grier</a></p>
        <p>To remove all the perceptions, that other people may have about this person/thing, and just looking at them solely off what their capable of</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>Exactly</p>                
        <p>And i think that if you can look at yourself like that, thats when you reach that flow state where its no roadblocks really just continuation</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>To remove the perceptions you have on yourself.</p>
        <p>Or what other people expect from you</p>
        <p>The things you can do r limitless</p>
        <p>And there's no right or wrong</p>
        <p>Tight or <img src="/interview-images/emoji-corn.png" className={styles.emoji}/></p>
        <p>Just knowing your intentions is just to make work</p>
        <p>Doing something different than what you did before</p>
      </div>

      <div className={styles.messagesBlock}>
        <p><a href="#" style={{ color: '#ed2024' }} onClick={(e) => handleReferenceClick(e, 'curiosity')}>Following your curiosity</a></p>
        <p>I think thats what impressed me so much about <a href="#" style={{ color: 'rgb(8 68 218)' }} onClick={(e) => handleReferenceClick(e, 'virgil')}>Virgils</a> practice, he found a way to monetize exploring his curiosity at the highest level</p>
        <p>Like how can i get paid to wake up and make whatever I want</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>I never really thought of it like that</p>
        <p>Like all his stuff was really about asking questions</p>
        <p>Finding a excuse to work with a artist he fucc with</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>Yeah like 'can we do this' what happens if...'</p>
        <p>Like i'm doing this art/creative direction thing but what it really looks like is getting someone to catch a fill on the outside of this louie building legally</p>
        <p>That's deff my goal</p>
        <p>Wake up and make anything I want</p>
        <p>In a sustainable comfortable way</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>I think that's where you can create the most longevity for your Self</p>
        <p>You should constantly want to do something fresh</p>
        <p>It's OK to repeat and go back to things that you already done before</p>
        <p>But never be afraid to do something because you haven't done it</p>
        <div className={styles.dialog}>
          <a href="https://www.instagram.com/p/ClBN6VmNitX/?igshid=MDVlOGU2MWU%3D" target="_blank">
            <img src="/interview-images/DIALOG/315658119_3190987884547079_4324968446667046805_n.jpeg" />
            <div className={styles.dialogCaption}>
              Lalit Patel on Instagram: "एटीट्यूड अपना बिंदास रखता हूं इसीलिए अपने दुश्मनो के दिलो में खौफ रखता हूं..!"
            </div>
          </a>
        </div>
        <p>Wanna shoot with bro so bad</p>
      </div>

      <div className={styles.messagesBlock}>
        <p><img src="/interview-images/emoji-tears-of-joy.png" className={styles.emoji} style={{ marginLeft: 0 }}/><img src="/interview-images/emoji-fire.png" className={styles.emoji}/></p>
        <p>Bruhh</p>
        <p>Fuckk</p>
        <p>Thatd be so hard lol</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>I would fly to India and everything</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>Like yo camera club</p>
        <p>Lets make this happen</p>
        <p><img src="/interview-images/emoji-crying.png" className={styles.emoji} style={{ marginLeft: 0 }}/></p>
      </div>
      
      <div className={styles.messagesBlock}>
        <p>
          <img src="/interview-images/emoji-tears-of-joy.png" className={styles.emoji} style={{ marginLeft: 0 }}/>
          <img src="/interview-images/emoji-tears-of-joy.png" className={styles.emoji} />
          <img src="/interview-images/emoji-tears-of-joy.png" className={styles.emoji} />
        </p>
        <p>Lowkey</p>
        <p>You fuck with <a href="#" style={{ color: '#ed1887' }} onClick={(e) => handleReferenceClick(e, 'richard_prince')}>Richard Prince</a>?</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>Fr lil treatment n all dat like lets run it</p>
        <p>Yeahh i do actually</p>
      </div>

      <div className={styles.messagesBlock}>
      <div className={styles.dialog}>
          <a href="https://www.youtube.com/watch?v=Wa8Zz8kjtQE" target="_blank">
            <img src="/interview-images/DIALOG/Wa8Zz8kjtQE-HD.jpg" />
            <div className={styles.dialogCaption}>
              "He is like a scavenger for all of us." | 5 Artists on Richard Prince | Louisiana Channel
            </div>
          </a>
        </div>
        <p>They got this new video talking bout his work</p>
        <p>Was bout to watch it</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>I really fuck w his <a href="#" style={{ color: '#502e91' }} onClick={(e) => handleReferenceClick(e, 'car_hoods')}>car hoods</a></p>
        <p>And some of the <a href="#" style={{ color: '#64bc46' }} onClick={(e) => handleReferenceClick(e, 'instagram_shit')}>instagram shit</a> grew on me too</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>I feel like all the stuff I made up to this point was inspirated by bro</p>
        <p>When it comes to my art practice</p>
        <p>Like these <a href="#" style={{ color: '#3d53a4' }} onClick={(e) => handleReferenceClick(e, 'acrylic_nails')}>acrylic nails</a> I did a few years back were hella inspired by those car hood</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>Consciously or unconsciously?</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>Unconsciously</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>Thats crazy to hear</p>
        <p>I love that work</p>
        <p>Some of my favorite from u</p>
        <p>did you u know about his work already</p>
        <p>Or find out after</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>Already did</p>
        <p>When I look back at it, it kind of makes sense cause that's all I was fucking with at the time</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>I feel like i used to hate on alot of white artists work or purposely only look at "black" shit for a minute</p>
        <p>But that goes back to what we were talking about</p>
        <p>I was limiting my view cause of pre conceived notions</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>Broo</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>Instead of taking what worked for me from anything i saw/hear/was curious about</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>I feel like it could be dangerous only looking at work that speak to u</p>
        <p>Because then you start making replicas of it</p>
        <p>Like the reason why there's a million black figure painter because all niggas look at is <a href="#" style={{ color: '#387f4b' }} onClick={(e) => handleReferenceClick(e, 'henry_taylor')}>Henry Taylor</a></p>
      </div>

      <div className={styles.messagesBlock}>
        <p>And <a href="#" style={{ color: '#cc1f49' }} onClick={(e) => handleReferenceClick(e, 'kehin')}>Kehin</a> and <a href="#" style={{ color: '#6fcee8' }} onClick={(e) => handleReferenceClick(e, 'kerry')}>Kerry</a></p>
      </div>

      <div className={styles.messagesBlock}>
        <p>When you look at something that isn't directly about you and your experience, you can take bits and pieces of it and create something new with your own shit</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>Yeah like I think its all important but the best references are out of your zone.  weather that in concept or even medium</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>Yeah</p>
        <p>I feel like that was <a href="#" style={{ color: '#ef5081' }} onClick={(e) => handleReferenceClick(e, 'spike_lee')}>Spike Lee's</a> downfall</p>
        <p>A lil bit tho</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>Damn never thought of it like that but so true</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>He kept on coming from the same vantage point</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>Instead of tryna make the best films he was tryna make the blackest films at a certain point</p>
      </div>                           

      <div className={styles.messagesBlock}>
        <p>by the 10th movie it gets played out</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>Exactly</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>But the reason why <a href="#" style={{ color: '#6fc38d' }} onClick={(e) => handleReferenceClick(e, 'do_the_right')}>"do the right thing"</a> was so crazy because there was no movies like that at the time</p>
        <p>A story that was told at that level</p>
        <p>But idk</p>
        <p>Now that I think about it</p>
        <p>He has made different movie</p>
        <p><a href="#" style={{ color: 'rgb(181 24 181)' }} onClick={(e) => handleReferenceClick(e, 'hour_25')}>25th hour</a> different</p>
        <p>Never seen that one tho</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>Never seen <a href="#" style={{ color: 'rgb(181 24 181)' }} onClick={(e) => handleReferenceClick(e, 'hour_25')}>25th</a></p>
        <p>Gotta check it out</p>
        <p>I like <a href="#" style={{ color: '#c82127' }} onClick={(e) => handleReferenceClick(e, 'girl_6')}>girl 6</a> and <a href="#" style={{ color: 'rgb(18 169 31)' }} onClick={(e) => handleReferenceClick(e, 'clockers')}>clockers</a> alot</p>
        <p>More for cinematography tho</p>
        <p>And <a href="#" style={{ color: '#4b2368' }} onClick={(e) => handleReferenceClick(e, 'bamboozled')}>bamboozled</a> just cause I think its such an ambitious concept</p>
        <p>But thay all still kinda fall in the lane we were saving</p>
        <p>that*</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>Exactly</p>
        <p>This black cinema thing</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>Damn <a href="#" style={{ color: 'rgb(181 24 181)' }} onClick={(e) => handleReferenceClick(e, 'hour_25')}>25th</a> looks interesting</p>
        <p>Love <a href="#" style={{ color: 'rgb(0 163 143)' }} onClick={(e) => handleReferenceClick(e, 'philip_seymour')}>Philip Seymour</a> rip <img src="/interview-images/emoji-folded-hands.png" className={styles.emoji}/> <img src="/interview-images/emoji-dove.png" className={styles.emoji}/></p>
        <p>Yeah i feel that... i feel like some of the older generation too looks at 'blackness' differently too cause ofc their experiences within/oof it are way diff than ours</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>I feel like a lot of the the older generation has a lot of self restrictions</p>
        <p>Due to there experience</p>
        <p>And it tends to be a handicap at times</p>
        <p>Like I shouldn't do this because of XYZ</p>
        <p>And if I do that, that's kind of like shitting on all the work that other people did before me</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>Yeah and even like with parents and family about communication and healing</p>
        <p>Ties back to what we keep getting to, letting all that shit back for the best result/to explore what feels right</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>Exactly</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>That's another big part of <a href="#" style={{ color: 'rgb(8 68 218)' }} onClick={(e) => handleReferenceClick(e, 'virgil')}>Virgils</a> success to me is being able to see both outlooks, the structure and reliability that comes with tradition and the freedom and energy that comes with letting go of all that</p>
        <p>Even <a href="#" style={{ color: '#f89e54' }} onClick={(e) => handleReferenceClick(e, 'kanye')}>Kanye</a></p>
      </div>            

      <div className={styles.messagesBlock}>
        <p>That's why <a href="#" style={{ color: 'rgb(13 154 145)' }} onClick={(e) => handleReferenceClick(e, 'yeezus')}>Yeezus</a> so good</p>
        <p>Wanted see how far he could go</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>Fr</p>
        <p>Yeah thats one of my favs</p>
        <p>For that reason</p>
        <p>And it aged so well imo</p>
        <p>Yeahh even like the <a href="#" style={{ color: '#ed2c92' }} onClick={(e) => handleReferenceClick(e, 'pink_polo')}>pink polo</a> stuff and being vulnerable</p>
        <p>Was that for the time</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>That's my favorite album by the man</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>I like that n <a href="#" style={{ color: '#467655' }} onClick={(e) => handleReferenceClick(e, 'eight_oh_eight')}>808</a></p>
        <p>And <a href="#" style={{ color: '#ed2024' }} onClick={(e) => handleReferenceClick(e, 'mbatf')}>mbdtf</a> but i think of that separately</p>
        <p>But yeah I feel like the same way as far as music like I'm definitely trying to do something different in terms of the way it actually sounds but I'm trying to go for like deep clear vulnerability as vulnerable as possible which i feel like is kinda the next progression</p>
        <p>like what if <a href="#" style={{ color: 'rgb(44 0 236)' }} onClick={(e) => handleReferenceClick(e, 'travis_scott')}>Travis Scott</a> was talking about going to therapy and facing trauma and shit lol</p>
        <p>not specifically by like u get my point lmao</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>I get u lol</p>
      </div>
      
      <div className={styles.messagesBlock}>
        <p>I feel like thats kinda our wave/bag Extreme vulnerability+astute/clever observations</p>
      </div>

      <div className={styles.messagesBlock}>
        <p>It is</p>
        <p>Being vulnerable but still Having fun with it</p>
      </div> 

      <div className={styles.messagesBlock}>
        <p>the most timeless shit</p>
        <p>Everything else either been done or played out</p>
      </div>
    </div>      
  )
}

export default InterviewMessages