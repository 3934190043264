import React, { useEffect, useRef, useState } from 'react'
import * as styles from '../pages/interview.module.scss'

const InterviewReferencePlayer = ({ reference, active }) => {
  const intervalRef = useRef(null)
  const [activeImageIndex, setActiveImageIndex] = useState(0)
  const [interacted, setInteracted] = useState(false)

  useEffect(() => {
    if (active) {
      // Always start at the first image?
      setActiveImageIndex(0)

      intervalRef.current = setInterval(() => {
        setActiveImageIndex(activeImageIndex => {
          return activeImageIndex === reference.images.length - 1 ? 0 : activeImageIndex + 1
        })
      }, 1200)

      setInteracted(true)
    }
    else {
      clearInterval(intervalRef.current)
    }

    return () => {
      clearInterval(intervalRef.current)
    }
  }, [active])

  const classNames = [styles.backgroundImageFrame]

  if (active) {
    classNames.push(styles.backgroundImageFrameVisible)
  }  

  return (
    <div
      className={classNames.join(' ')}
    >
      {reference.images.map((image, i) => {
        const display = activeImageIndex == i ? '' : 'none'

        return (
          <div
            key={i}
            className={styles.backgroundImage}
            style={{ display }}
          >
            {(i === 0 || interacted) ? <img src={image} /> : '' }
          </div>
        )
      })}
    </div>
  )
}

export default InterviewReferencePlayer