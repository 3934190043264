// extracted by mini-css-extract-plugin
export var background = "interview-module--background--eca64";
export var backgroundImage = "interview-module--backgroundImage--ed7f9";
export var backgroundImageFrame = "interview-module--backgroundImageFrame--4e18e";
export var backgroundImageFrameVisible = "interview-module--backgroundImageFrameVisible--e4ca5";
export var backgroundVisible = "interview-module--backgroundVisible--6acdd";
export var column = "interview-module--column--3c8a0";
export var dialog = "interview-module--dialog--bf2c6";
export var dialogCaption = "interview-module--dialogCaption--bf8b5";
export var emoji = "interview-module--emoji--941c7";
export var headerImage = "interview-module--headerImage--297da";
export var messages = "interview-module--messages--b0416";
export var messagesBlock = "interview-module--messagesBlock--ba01d";
export var title = "interview-module--title--c5f3f";
export var titleHidden = "interview-module--titleHidden--d00a3";
export var wrapper = "interview-module--wrapper--7bffa";